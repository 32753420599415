@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Roboto";
  src: url("./assets/fonts/roboto_regular.ttf") format("truetype"),
    url("./assets/fonts/roboto_medium.ttf") format("truetype"),
    url("./assets/fonts/roboto_light.ttf") format("truetype"),
    url("./assets/fonts/roboto_bold.ttf") format("truetype");
}

* {
  outline: none;
}

.subtitle {
  inline-size: max-content;
}
.video {
  text-align: center;
  z-index: 8;
  right: 0;
  height: 90vh;
  width: 100%;
  object-fit: fill;
}

@media only screen and (max-width: 767px) {
  .video {
    height: 285px;
  }
}

::-webkit-scrollbar {
  width: 5px;
  height: 10px;
}

::-webkit-scrollbar-track {
 background: #dadada;
}

::-webkit-scrollbar-thumb {
 background: #0f6af9;
 border-radius: 5px;
}

.border-radius video{
  border-radius: 7px !important;
}

.video-screen video{ 
  transform: scaleX(-1);
  border-radius: 7px !important;
}

.video-call {
  transform: scaleX(-1);
}


.fix-height-show, .fix-height video{
  height: calc(100vh - 80px) !important;
  border-radius: 15px;
}
.fix-height {
  border-radius: 15px;
  background: black;
}

.fix-height-lobby-screen video{
  overflow: clip;
}

.video-fix-height-show {
  align-content: center;
  height: calc(100vh - 64px) !important;
}

.video-call-room-video video{
  border-radius: 15px;
}

.submit-btn {
  color: white;
  background: #0d6efd;
  border-radius: 10px;
  padding: 15px 30px;
  font-weight: 500;
}