.guide_line_container {
  background: white;
  padding: 15px;
  border-radius: 3px;
  border: 1px solid #3e3ee9;
  overflow-y: scroll;
  height: 350px;
  width: 800px;
}

.guide_line_container.join-div {
  height: 100%;
  width: 100%;
  max-height: 350px;
  /* max-width: 800px; */
}

.guide_line_bg_box.join-div {
  width: 100%;
  height: 100%;
  /* max-height: 500px; */
  /* max-width: 600px; */
}

.guide_line_bg_box {
  border: 1px solid gainsboro;
  width: 850px;
  border-color: #c7c7c7;
  border-radius: 10px;
  text-align: center;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
  padding: 30px;
  height: 600px;
  position: relative;
}

.disable_submit_button {
  padding: 5px 15px;
  border-radius: 10px;
  background: #b1b1b6;
  color: white;
  cursor: not-allowed;
}

.margin_top {
  margin-top: 80px;
}

.accept_button {
  color: #0d6efd;
  min-width: 200px;
  border-radius: 150px;
  padding: 15px 30px;
  font-weight: 500;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.45);
}

.accept_button:hover {
  background: #0d6efd;
  color: white;
}

.disable_accept_button {
  background: gainsboro;
  cursor: not-allowed;
  /* border: 1px solid #0d6efd; */
  color: #0d6efd;
  min-width: 200px;
  border-radius: 150px;
  padding: 15px 30px;
  font-weight: 500;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.45);
}

.paragraph_text {
  font-size: 14px;
}

.heading_text {
  font-size: 18px;
}
